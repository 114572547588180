"use client";

import Link from 'next/link';
import { FC } from 'react';
import Script from 'next/script';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
const Custom404: FC = () => {
  const searchParams = useSearchParams();
  const token = searchParams.get('token');
  const [jQueryLoaded, setJQueryLoaded] = useState(false);
  const [scriptContent, setScriptContent] = useState<string | null>(null);
  // Fetch the local JavaScript file
  useEffect(() => {
    if (jQueryLoaded) {
      fetch('/scripts/404/handler.js').then(response => response.text()).then(script => {
        setScriptContent(script); // Store the script content
      }).catch(err => console.error('Failed to load the script', err));
    }
  }, [jQueryLoaded]);

  // Dynamically inject the script into the document
  useEffect(() => {
    if (scriptContent) {
      const script = document.createElement('script');
      script.innerHTML = scriptContent;
      document.body.appendChild(script);
    }
  }, [scriptContent]);
  return <>
      {token ? <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-2xl">Loading...</h2>
          <p>Please wait while we process your request.</p>
        </div>
      </div> : <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
          <p className="mt-4">The page you are looking for does not exist.</p>
          <Link href="/" className="mt-6 text-blue-500 hover:underline">
            Go back to Home
          </Link>
        </div>}
      {/* Load jQuery from CDN */}
      <Script src="https://code.jquery.com/jquery-3.6.0.min.js" strategy="afterInteractive" onLoad={() => setJQueryLoaded(true)} data-sentry-element="Script" data-sentry-source-file="not-found.tsx" />
      
    </>;
};
export default Custom404;

// http://localhost:3000/auth/email/verify?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWdpc3RyYXRpb25JZCI6IjUyMjUyIiwiZW1haWwiOiJqYXkrMjIxMkBnZW9jbG91ZC5jbyIsImlhdCI6MTcyODc4MDYwNywiZXhwIjoxNzYwMzE2NjA3fQ.7PCCouiMUID47SfhElEiFFXpJQWvOU1bERGLdfbSSkY&callback_url=https%3A%2F%2Fugeo.app&base_url=http%3A%2F%2Fugeo-api.vercel.app&_branch_match_id=1366960078261684159&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz8nMy9YtSS0u0StNT83XSywo0A8sCraoLPBPdfVLsq8rSk1LLSrKzEuPTyrKLy9OLbJ1zijKz00FAJ7xHYVAAAAA